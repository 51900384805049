@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");

.font-sans {
  font-family: "Open Sans", sans-serif;
}
.font-serif {
  font-family: "Roboto", sans-serif;
}

body {
  @apply font-sans font-medium text-neutral-700;
}
body a {
  @apply no-underline text-sky-700;
}
.gray-shadow {
  box-shadow: 10px 0px 14px 0px rgba(0, 0, 0, 0.2);
}
nav a {
  @apply font-serif text-sm text-sky-600 hover:text-sky-400 font-semibold;
}
body p {
  @apply mb-3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  line-height: 1.2em !important;
}
h1 {
  @apply text-lg md:text-xl lg:text-2xl font-bold drop-shadow-md mb-4;
}
h2 {
  @apply md:text-lg lg:text-xl font-bold mb-4 text-neutral-800;
}
h3 {
  @apply text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-neutral-800;
}
h4 {
  @apply text-base lg:text-lg font-bold text-neutral-800 mb-4;
}
h5 {
  @apply text-base md:text-lg lg:text-xl font-medium text-neutral-800 mb-4;
}
h6 {
  @apply text-base lg:text-lg font-semibold text-neutral-800 mb-4;
}

.title-heading2:before {
  @apply bg-fixed absolute bg-blue-600 bg-opacity-80 bottom-0 left-0 right-0 top-0 w-full overflow-hidden -z-10;
}

.page-heading {
  @apply object-cover bg-no-repeat bg-center relative overflow-hidden;
}
.page-heading:before {
  @apply bg-fixed absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.gallery-effect {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden p-3;
}
.gallery-effect:before {
  @apply bg-black bg-opacity-40 absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}
.col-justify p {
  @apply text-justify;
}
.whitebox {
  @apply bg-white border-2 border-gray-300 rounded-3xl mb-8 p-8;
  h6 {
    @apply font-bold text-black;
  }
}
.embla__container {
  display: flex;
  @apply gap-10;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  @apply gap-10;
}

.embla {
  overflow: hidden;
}

.embla__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.embla__slide.is-selected {
  opacity: 1;
  z-index: 1; /* Ensure the selected slide is on top */
}

.embla__slide__img {
  display: block;
  width: 100%;
  height: auto;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

.gallery-item {
  cursor: pointer;
  overflow: hidden;
  border-radius: 2px;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.slick-slide {
  padding: 0 8px;
}

.slick-list {
  margin: 0 -10px;
}

.btn-green {
  @apply rounded-full font-medium text-white bg-blue-500 hover:bg-blue-500 transition-all text-xs py-2 px-6;
}
.btn-orange {
  @apply rounded-full font-medium text-white bg-orange-500 hover:bg-orange-500 transition-all text-sm py-2 px-2;
}
.btn-blue {
  @apply rounded-full font-medium text-white bg-blue-600 hover:bg-red-500 transition-all text-sm py-3 px-5;
}

.szh-accordion__item {
  @apply rounded-md border mb-3 p-4;
}
.szh-accordion__item h3 {
  @apply font-bold text-xl text-blue-500;
}
.szh-accordion__item-content {
  @apply pt-4;
}

footer {
  @apply text-white;
  .title {
    @apply text-sm font-bold uppercase text-white mt-6 mb-4;
  }
}
